<template>
  <div class="up_plan_main">
    <CAlert color="danger" v-if="isExpired">
      Your Plan is Expired. Please Upgrade Your Plan.
    </CAlert>
    <CRow>
      <CCol class="text-center">
        <h3 class="font-weight-bold">Your Current Plan Info</h3>
      </CCol>
    </CRow>
    <CCard class="p-3" style="background: azure">
      <CRow>
        <CCol class="text-right">
          <h4>Plan Name :</h4>
        </CCol>
        <CCol>
          <h4>
            <strong>{{ plan_details.plan_name }}</strong>
          </h4>
        </CCol>
      </CRow>
      <CRow>
        <CCol class="text-right">
          <h4>Validity :</h4>
        </CCol>
        <CCol>
          <h4>
            <strong>{{ plan_details.validity }}</strong>
          </h4>
        </CCol>
      </CRow>
      <CRow :class="isExpired?`text-danger`:`text-black`">
        <CCol class="text-right">
          <h4>Expiry Date :</h4>
        </CCol>
        <CCol>
          <h4>
            <strong>{{ changeDateFormate(plan_details.expiry_date) }}</strong>
          </h4>
        </CCol>
      </CRow>
      <CRow>
        <CCol class="text-right">
          <h4>Total Clicks :</h4>
        </CCol>
        <CCol>
          <h4>
            <strong>{{ plan_details.total_clicks }}</strong>
          </h4>
        </CCol>
      </CRow>
      <CRow>
        <CCol class="text-right">
          <h4>Remaining Clicks :</h4>
        </CCol>
        <CCol>
          <h4>
            <strong>{{
              plan_details.total_clicks - plan_details.receive_clicks
            }}</strong>
          </h4>
        </CCol>
      </CRow>
    </CCard>
    <div v-if="plan_details.planType != 3">
    <CRow class="justify-content-center">
      <CCol class="col-md-2 col-12" v-if="isPlanActive == false || plan_details.planType == 1">
          <CSelect :options="plan_opt"
            :value.sync="checkout.plan_id"></CSelect>
      </CCol>
      <CCol class="col-md-2 col-12" v-if="isPlanActive == false || plan_details.planType == 1">
        <!-- <span>&nbsp;</span> -->
        <CButton
          class="btn btn-info"
          style="width: 100%"
          @click="checkoutPlan()"
          >Checkout</CButton
        >
      </CCol>
      <CCol class="col-md-2 col-12" v-if="isPlanActive != false">
        <!-- <span>&nbsp;</span> -->
        <CButton
          class="btn btn-danger"
          style="width: 100%"
          @click="cancelPlan()"
          >Cancel Subscription </CButton
        >
      </CCol>
      <CCol class="col-md-2 col-12" v-if="loading">
        <CSpinner color="info" v-if="loading"/>
      </CCol>
    </CRow>

    <CRow class="p-3">
      <CCol class="col-md-3 col-12">
        <div class="p-4 plan_card">
          <CRow class="p-3">
            <CCol class="col-md-10 col-10"
              ><span class="font-weight-bold text-2xl"
                >Free<span class="text-success">.</span></span
              ></CCol
            >
            <CCol>
              <svg
                fill="#000000"
                width="30px"
                height="30px"
                viewBox="0 0 32 32"
                version="1.1"
              >
                <title>alt-user</title>
                <path
                  d="M1.056 32h29.888q0-1.888-1.376-3.552t-3.808-2.88-5.504-1.696v-1.568q1.952-1.152 3.136-3.104t1.152-4.256v-0.224q0.928-0.32 1.536-1.12t0.608-1.856-0.608-1.856-1.536-1.12v-0.224q0-2.304-1.152-4.288t-3.104-3.104-4.288-1.152-4.288 1.152-3.104 3.104-1.152 4.288v0.224q-0.928 0.32-1.536 1.12t-0.576 1.856 0.576 1.856 1.536 1.12v0.224q0 2.304 1.184 4.256t3.104 3.104v1.568q-3.072 0.544-5.504 1.696t-3.808 2.88-1.376 3.552z"
                ></path>
              </svg>
            </CCol>
          </CRow>
          <CRow>
            <CCol class="text-left">
              <div class="text-xl font-medium">
                <sup class="text-lg text-grey-main">$</sup>
                <span
                  class="mx-1 text-4xl font-bold text-grey-light line-through"
                ></span>
                <span class="text-4xl font-bold">0</span>
                <span class="text-grey-main">/ month</span>
              </div>
            </CCol>
          </CRow>
          <CRow>
            <CCol>
              <div
                class="py-4 pt-2 text-left text-base font-medium text-grey-main lg:h-[72px]"
              >
                For smaller businesses and individual users.
              </div>
            </CCol>
          </CRow>
          <!-- <CRow>
            <CCol>
              <CButton
                class="btn btn-light btn-lg btn-pill"
                style="width: 100%"
                disabled
                >Not Available</CButton
              >
            </CCol>
          </CRow> -->
          <CRow>
            <CCol>
              <div class="pb-3 text-left text-2xl font-weight-bold">
                <span>Up to 100,000</span> clicks
              </div>
            </CCol>
          </CRow>
          <CRow>
            <CCol>
              <ul class="text-left font-medium">
                <li>Full access to all features and functionalities</li>
                <li>Up to 100,000 clicks allowed per month</li>
                <li>Reliable and secure service</li>
                <li>Real-time analytics and reporting</li>
                <li>Non-renewable plan, no automatic renewal required</li>
                <li>Flexible cancellation policy</li>
              </ul>
            </CCol>
          </CRow>
        </div>
      </CCol>

      <CCol class="col-md-3 col-12 mt-3 mt-md-0">
        <div class="p-4 plan_card">
          <CRow class="p-3">
            <CCol class="col-md-10 col-10"
              ><span class="font-weight-bold text-2xl"
                >Power 200<span class="text-success">.</span></span
              ></CCol
            >
            <CCol>
              <svg
                fill="#000000"
                width="30px"
                height="30px"
                viewBox="0 0 32 32"
                version="1.1"
              >
                <title>alt-user</title>
                <path
                  d="M1.056 32h29.888q0-1.888-1.376-3.552t-3.808-2.88-5.504-1.696v-1.568q1.952-1.152 3.136-3.104t1.152-4.256v-0.224q0.928-0.32 1.536-1.12t0.608-1.856-0.608-1.856-1.536-1.12v-0.224q0-2.304-1.152-4.288t-3.104-3.104-4.288-1.152-4.288 1.152-3.104 3.104-1.152 4.288v0.224q-0.928 0.32-1.536 1.12t-0.576 1.856 0.576 1.856 1.536 1.12v0.224q0 2.304 1.184 4.256t3.104 3.104v1.568q-3.072 0.544-5.504 1.696t-3.808 2.88-1.376 3.552z"
                ></path>
              </svg>
            </CCol>
          </CRow>

          <CRow>
            <CCol class="text-left">
              <div class="text-xl font-medium">
                <sup class="text-lg text-grey-main">$</sup>
                <span
                  class="mx-1 text-4xl font-bold text-grey-light line-through"
                ></span>
                <span class="text-4xl font-bold">200</span>
                <span class="text-grey-main">/ month</span>
              </div>
            </CCol>
          </CRow>
          <CRow>
            <CCol>
              <div
                class="py-4 pt-2 text-left text-base font-medium text-grey-main lg:h-[72px]"
              >
                Medium-sized businesses and professional users.
              </div>
            </CCol>
          </CRow>
          <!-- <CRow>
            <CCol>
              <CButton
                class="btn btn-success btn-lg btn-pill"
                style="width: 100%"
                >Upgrade plan</CButton
              >
            </CCol>
          </CRow> -->
          <CRow>
            <CCol>
              <div class="pb-3 text-left text-2xl font-weight-bold">
                <span>Up to 200,000</span> clicks
              </div>
            </CCol>
          </CRow>
          <CRow>
            <CCol>
              <ul class="text-left font-medium">
                <li>
                  Comprehensive access to all features and functionalities
                </li>
                <li>Up to 200,000 clicks allowed per month</li>
                <li>Reliable and secure service</li>
                <li>Real-time analytics and reporting</li>
                <li>Renewable plan with automatic monthly renewal</li>
                <li>Flexible cancellation policy</li>
              </ul>
            </CCol>
          </CRow>
        </div>
      </CCol>
      <CCol class="col-md-3 col-12 mt-3 mt-md-0">
        <div class="p-4 plan_card">
          <CRow class="p-3">
            <CCol class="col-md-10 col-10"
              ><span class="font-weight-bold text-2xl"
                >Power 500<span class="text-success">.</span></span
              ></CCol
            >
            <CCol>
              <svg
                fill="#000000"
                version="1.1"
                id="Capa_1"
                width="30px"
                height="30px"
                viewBox="0 0 80.13 80.13"
                xml:space="preserve"
              >
                <g>
                  <path
                    d="M48.355,17.922c3.705,2.323,6.303,6.254,6.776,10.817c1.511,0.706,3.188,1.112,4.966,1.112
                            c6.491,0,11.752-5.261,11.752-11.751c0-6.491-5.261-11.752-11.752-11.752C53.668,6.35,48.453,11.517,48.355,17.922z M40.656,41.984
                            c6.491,0,11.752-5.262,11.752-11.752s-5.262-11.751-11.752-11.751c-6.49,0-11.754,5.262-11.754,11.752S34.166,41.984,40.656,41.984
                            z M45.641,42.785h-9.972c-8.297,0-15.047,6.751-15.047,15.048v12.195l0.031,0.191l0.84,0.263
                            c7.918,2.474,14.797,3.299,20.459,3.299c11.059,0,17.469-3.153,17.864-3.354l0.785-0.397h0.084V57.833
                            C60.688,49.536,53.938,42.785,45.641,42.785z M65.084,30.653h-9.895c-0.107,3.959-1.797,7.524-4.47,10.088
                            c7.375,2.193,12.771,9.032,12.771,17.11v3.758c9.77-0.358,15.4-3.127,15.771-3.313l0.785-0.398h0.084V45.699
                            C80.13,37.403,73.38,30.653,65.084,30.653z M20.035,29.853c2.299,0,4.438-0.671,6.25-1.814c0.576-3.757,2.59-7.04,5.467-9.276
                            c0.012-0.22,0.033-0.438,0.033-0.66c0-6.491-5.262-11.752-11.75-11.752c-6.492,0-11.752,5.261-11.752,11.752
                            C8.283,24.591,13.543,29.853,20.035,29.853z M30.589,40.741c-2.66-2.551-4.344-6.097-4.467-10.032
                            c-0.367-0.027-0.73-0.056-1.104-0.056h-9.971C6.75,30.653,0,37.403,0,45.699v12.197l0.031,0.188l0.84,0.265
                            c6.352,1.983,12.021,2.897,16.945,3.185v-3.683C17.818,49.773,23.212,42.936,30.589,40.741z"
                  />
                </g>
              </svg>
            </CCol>
          </CRow>

          <CRow>
            <CCol class="text-left">
              <div class="text-xl font-medium">
                <sup class="text-lg text-grey-main">$</sup>
                <span
                  class="mx-1 text-4xl font-bold text-grey-light line-through"
                ></span>
                <span class="text-4xl font-bold">500</span>
                <span class="text-grey-main">/ month</span>
              </div>
            </CCol>
          </CRow>
          <CRow>
            <CCol>
              <div
                class="py-4 pt-2 text-left text-base font-medium text-grey-main lg:h-[72px]"
              >
                For larger businesses with higher click requirements.
              </div>
            </CCol>
          </CRow>
          <!-- <CRow>
            <CCol>
              <CButton
                class="btn btn-success btn-lg btn-pill"
                style="width: 100%"
                >Upgrade plan</CButton
              >
            </CCol>
          </CRow> -->
          <CRow>
            <CCol>
              <div class="pb-3 text-left text-2xl font-weight-bold">
                <span>Up to 600,000</span> clicks
              </div>
            </CCol>
          </CRow>
          <CRow>
            <CCol>
              <ul class="text-left font-medium">
                <li>Full access to all features and functionalities</li>
                <li>Generous click limit of up to 600,000 clicks per month</li>
                <li>Reliable and secure service</li>
                <li>Real-time analytics and reporting</li>
                <li>Renewable plan with automatic monthly renewal</li>
                <li>Flexible cancellation policy</li>
              </ul>
            </CCol>
          </CRow>
        </div>
      </CCol>
      <CCol class="col-md-3 col-12 mt-3 mt-md-0">
        <div class="p-4 plan_card">
          <CRow class="p-3">
            <CCol class="col-md-10 col-10"
              ><span class="font-weight-bold text-2xl"
                >Power 1000<span class="text-success">.</span></span
              ></CCol
            >
            <CCol>
              <svg
                fill="#000000"
                version="1.1"
                id="Capa_1"
                width="30px"
                height="30px"
                viewBox="0 0 80.13 80.13"
                xml:space="preserve"
              >
                <g>
                  <path
                    d="M48.355,17.922c3.705,2.323,6.303,6.254,6.776,10.817c1.511,0.706,3.188,1.112,4.966,1.112
                            c6.491,0,11.752-5.261,11.752-11.751c0-6.491-5.261-11.752-11.752-11.752C53.668,6.35,48.453,11.517,48.355,17.922z M40.656,41.984
                            c6.491,0,11.752-5.262,11.752-11.752s-5.262-11.751-11.752-11.751c-6.49,0-11.754,5.262-11.754,11.752S34.166,41.984,40.656,41.984
                            z M45.641,42.785h-9.972c-8.297,0-15.047,6.751-15.047,15.048v12.195l0.031,0.191l0.84,0.263
                            c7.918,2.474,14.797,3.299,20.459,3.299c11.059,0,17.469-3.153,17.864-3.354l0.785-0.397h0.084V57.833
                            C60.688,49.536,53.938,42.785,45.641,42.785z M65.084,30.653h-9.895c-0.107,3.959-1.797,7.524-4.47,10.088
                            c7.375,2.193,12.771,9.032,12.771,17.11v3.758c9.77-0.358,15.4-3.127,15.771-3.313l0.785-0.398h0.084V45.699
                            C80.13,37.403,73.38,30.653,65.084,30.653z M20.035,29.853c2.299,0,4.438-0.671,6.25-1.814c0.576-3.757,2.59-7.04,5.467-9.276
                            c0.012-0.22,0.033-0.438,0.033-0.66c0-6.491-5.262-11.752-11.75-11.752c-6.492,0-11.752,5.261-11.752,11.752
                            C8.283,24.591,13.543,29.853,20.035,29.853z M30.589,40.741c-2.66-2.551-4.344-6.097-4.467-10.032
                            c-0.367-0.027-0.73-0.056-1.104-0.056h-9.971C6.75,30.653,0,37.403,0,45.699v12.197l0.031,0.188l0.84,0.265
                            c6.352,1.983,12.021,2.897,16.945,3.185v-3.683C17.818,49.773,23.212,42.936,30.589,40.741z"
                  />
                </g>
              </svg>
            </CCol>
          </CRow>

          <CRow>
            <CCol class="text-left">
              <div class="text-xl font-medium">
                <sup class="text-lg text-grey-main">$</sup>
                <span
                  class="mx-1 text-4xl font-bold text-grey-light line-through"
                ></span>
                <span class="text-4xl font-bold">1000</span>
                <span class="text-grey-main">/ month</span>
              </div>
            </CCol>
          </CRow>
          <CRow>
            <CCol>
              <div
                class="py-4 pt-2 text-left text-base font-medium text-grey-main lg:h-[72px]"
              >
                For businesses and enterprises with more complex requirements.
              </div>
            </CCol>
          </CRow>
          <!-- <CRow>
            <CCol>
              <CButton
                class="btn btn-success btn-lg btn-pill"
                style="width: 100%"
                >Upgrade Plan</CButton
              >
            </CCol>
          </CRow> -->
          <CRow>
            <CCol>
              <div class="pb-3 text-left text-2xl font-weight-bold">
                <span>Up to 1,500,000</span> clicks
              </div>
            </CCol>
          </CRow>
          <CRow>
            <CCol>
              <ul class="text-left font-medium">
                <li>Full access to all features and functionalities</li>
                <li>
                  Extensive click limit of up to 1,500,000 clicks per month
                </li>
                <li>Reliable and secure service</li>
                <li>Real-time analytics and reporting</li>
                <li>Renewable plan with automatic monthly renewal</li>
                <li>Flexible cancellation policy</li>
              </ul>
            </CCol>
          </CRow>
        </div>
      </CCol>
    </CRow>

    <CRow>
      <CCol>
        <div
          class="p-4 "
          style=""
        >
          If you're looking for a plan that offers even higher click limits or
          additional features, or if you have specific requirements that go
          beyond our standard plans, our team of experts is ready to assist you.
          We'll work closely with you to understand your unique needs and create
          a customized plan that meets your specific goals. To get started,
          simply
          <a href="https://scienlabs.com/#contactus" target="blank"
            >click here</a
          >
          to reach out to our sales team. They will be happy to discuss your
          requirements and provide you with a personalized quote based on your
          specific needs.
        </div>
      </CCol>
    </CRow>
  </div>
  </div>
</template>



<script>
import Swal from 'sweetalert2/dist/sweetalert2.js'
import moment from "moment";
import DashboardService from "./../services/dashboardController";
export default {
  name: "UpgradePlan",
  props: {
    update_plan_modal:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {
      plan_details: {planType:3},
      plan1collapse: false,
      plan_list:[],
      plan_opt:[{ value: null, label: 'Select Plan' }],
      checkout:{plan_id:''},
      isExpired:false,
      isPlanActive:false,
      loading:false,
      user:''
    };
  },
  watch:{
    update_plan_modal(){
      if(this.update_plan_modal == true){
        this.getPlanDetails()
        this.getPlan()
        this.hasSubscribed()
      }
    }
  },
  mounted() {
    this.user = window.localStorage.getItem("user");
    if(this.user == 'user'){
      this.hasSubscribed()
      this.getPlanDetails()
      this.getPlan()
    }
  },
  methods: {
    async getPlanDetails(){
      let response = await DashboardService.getplandetails();
      if(response.result){
      this.plan_details = response.message
      var today = new Date();
        var expiry_date = new Date(this.plan_details.expiry_date);
        if(today > expiry_date){
          this.isExpired = true
        }
      }
    },
    changeDateFormate(d) {
      var p = new Date(d);
      return moment.utc(d).format("MMMM D, YYYY");
    },
    async getPlan(){
      this.plan_list=[]
      this.plan_opt=[{ value: null, label: 'Select Plan' }]
      let response = await DashboardService.getplanlist();
      if(response.result){
      this.plan_list = response.data
      this.plan_list.forEach(element => {
        this.plan_opt.push({ value: element._id, label: element.plan_name })
      });
      }
    },
    async checkoutPlan(){
      if(this.checkout.plan_id == ''){
        Swal.fire({title: 'Error!',text: 'Please select plan',icon: 'error',confirmButtonText: 'Close',position: 'top-end',showConfirmButton: false,timer: 1000})
        return false
      }
      let payload = { plan_id:this.checkout.plan_id }
      let response = await DashboardService.checkOut(payload);
      if(response.result){
        window.location.replace(response.data);
      }
      else{
        Swal.fire({title: 'Error!',text: response.message,icon: 'error',confirmButtonText: 'Close',position: 'top-end',showConfirmButton: false,timer: 3000})
      }
    },
    async cancelPlan(){
      if(confirm("Are you sure you want to cancel your subscription?") == false){
        return false
      }
      this.loading = true;
      let response = await DashboardService.cancelPlan();
      if(response.result){
        this.isPlanActive = false;
        Swal.fire({title: 'Success!',text: response.message,icon: 'success',confirmButtonText: 'Close',position: 'top-end',showConfirmButton: false,timer: 3000})
       }
       else{
        Swal.fire({title: 'Error!',text: response.message,icon: 'error',confirmButtonText: 'Close',position: 'top-end',showConfirmButton: false,timer: 3000})
       }
       this.loading = false;
    },
    async hasSubscribed(){
      let response = await DashboardService.hasSubscribed();
      if(response.result){
        this.isPlanActive = response.data.hasSubscribed
      }
    }
  },
};
</script>



<style>
.font-medium {
  font-weight: 500;
}
.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}
.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}
.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}
.text-grey-main {
  --tw-text-opacity: 1;
  color: rgb(104 119 144 / var(--tw-text-opacity));
}
.plan_card {
  border-radius: 15px;
  /* box-shadow: 0px 2px 10px 1px rgb(135, 172, 240); */
  box-shadow: 0px 0px 15px 0px rgb(135, 172, 240);
  font-size: 18px; font-weight: 600;
  /* border-left: 1px solid rgb(23, 50, 185);
  border-top: 1px solid rgb(23, 50, 185); */
}
</style>